.side-panel {
  background: $neutral-1;
  position: fixed;
  overflow: auto;
  padding: 16px;
  width: 350px;
  bottom: 0;
  top: 0;
  scroll-behavior: smooth;

  &:has(.side-panel-header.top-sticky-blur) { padding-top: 0;}

  &-left {
    z-index: 1;
    left: 64px;
  
    &::after {
      content: "";
      box-shadow: inset -4px 0px 4px -4px rgba(0, 0, 0, 0.05), inset -16px 0px 16px -16px rgba(0, 0, 0, 0.03);
      pointer-events: none;
      position: fixed;
      display: block;
      height: 100vh;
      width: 32px;
      left: 382px;
      top: 0;

      .hub-editor &, .hub-templates &, .transcript-editor &, .widget-editor & { left: 318px;}
      .main-library & { display: none;}
    }

    &:has(.user-avatar.size-xl):after { z-index: 3;}
  }

  &-template {
    .modal-right & {
      .with-topbar & { min-height: 100vh;}
    }

    &-count { @include flexbox(row,center,initial);}

    &-drop {
      .icon-sm { @include transition(.15s);}
      &.collapsed { .icon-sm {transform: rotate(-90deg);}}
    }
  }

  .iframe-holder {
    border-radius: $border-radius-lg;
    overflow: hidden;
  }

  &-wrap {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding: 24px 24px 0 24px;
    margin-bottom: 0;

    &:last-of-type {
      padding-bottom: 0;
    }
  }
  /*
  @cristian you can not use classes this way, it breaks other parts of the app
  &-widget {
    .txt-body {
      @include flexbox(initial, center, flex-start);
      .txt-heading-small {
        margin-bottom: 0;
        color: $neutral-6;
      }
    }
    span {
      svg {
        margin-right: 10px;
      }
    }
  }
  */

  &-section-type {
    @include flexbox(initial, center, flex-start);
  }
  &-inner {
    flex: 1 0 0;
    max-width: 100%;
    &-content {
      max-width: 600px;
    }
  }

  @import "side-panel/header";
  @import "side-panel/group";
  @import "side-panel/option";
  @import "side-panel/draggable";
  @import "side-panel/option-page";
  @import "side-panel/option-folder";
  @import "side-panel/option-menu";
  @import "side-panel/toggle";
  @import "side-panel/title";
  @import "side-panel/description";
  @import "side-panel/segment";
  @import "side-panel/section";
  @import "side-panel/model";
  @import "side-panel/library";
  @import "side-panel/metadata";
  @import "side-panel/playlist";
  @import "side-panel/file";
  @import "side-panel/link-row";
  @import "side-panel/collapse";
  @import "side-panel/chapter-row";
  @import "side-panel/button-place-picker";
  @import "side-panel/background-position";
  @import "side-panel/plans";
  @import "side-panel/preview";
}
